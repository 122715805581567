import userManagementServices from "@/services/user-management.service";

export default {
  userBenefits: {
    name: "user_management_benefits",
    entities: [
      {
        name: "ID",
        type: "string",
        readonly: true
      },
      {
        name: "Benefit Name",
        type: "string",
        readonly: true,
        allowEmpty: false,
        required: true
      },
      {
        name: "Eligibility Date",
        type: "date",
        readonly: false
      },

      {
        name: "Enrolled",
        type: "dropdown",
        required: true,
        readonly: false,
        optionsService: () => [
          { id: 0, name: "No" },
          { id: 1, name: "Yes" }
        ]
      }
    ],
    services: {
      fetchRecord: () => {},
      fetchData: userManagementServices.fetchUserBenefits,
      //table will send event to parent form and save method will be called there
      saveRecord: userManagementServices.saveUserBenefits
    }
  }
};

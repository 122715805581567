import mileageRateService from '@/services/Reimbursements/mileage-rate.service'

export default {
  mileageRate: {
    name: 'mileage_rate',
    entities: [
      {
        name: 'ID',
        type: 'string',
        readonly: true
      },
      {
        name: 'Date',
        type: 'date',
        readonly: false,
        defaultRange: 'This year full',
        required: true
      },
      {
        name: 'Rate',
        type: 'number',
        readonly: false,
        required: true
      },
      {
        name: 'Description',
        type: 'string',
        readonly: false,
        allowEmpty: false,
        required: true
      }
    ],
    services: {
      fetchRecord: () => {},
      fetchData: mileageRateService.fetchMileageRates,
      saveRecord: mileageRateService.saveMileageRate,
      deleteRecord: mileageRateService.deleteMileageRate
    },
    actions: {
      Create: {
        validator: () => true
      },
      Delete: {
        validator: () => true
      }
    }
  }
}

import { api } from "@/shared/api";
import modelHelpers from "@/models/helpers";

const shiftTemplates = {
  fetchShiftTemplate: async id => {
    let response = await api.get(`timetracker/shift-templates/${id}`);

    response["Group"] = modelHelpers.parseJSON(response["Group_JSON"]);

    response["Responsibles"] = modelHelpers.parseJSONArray(
      response["Responsibles_JSON"]
    );

    return response;
  },
  fetchShiftTemplates: async payload => {
    let response = await api.post(`timetracker/shift-templates`, payload);

    /*
    for (let item of response) {
      let users = modelHelpers.parseJSONArray(item["Responsibles"]);
      if (users.length) item["Responsibles"] = users.map(u => u.label).join(",");
      else item["Responsible"] = "";
    }*/

    return response;
  },
  cloneShiftTemplates: async payload => {
    return api.post(`timetracker/shift-templates/clone`, payload);
  },
  saveShiftTemplate: async payload => {
    //if new record, mark as template
    if (!payload.ID) {
      payload["Is Template"] = 1;
    }

    let url = payload.ID
      ? `timetracker/shift-templates/${payload.ID}`
      : `timetracker/shift-templates`;

    return api.put(url, payload);
  },
  deleteShiftTemplate: async ID => {
    return api.delete(`timetracker/shift-templates/${ID}`);
  }
};

export default shiftTemplates;

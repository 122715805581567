import warehouseServices from "@/services/warehouse.service";
import dictionaryServices from "@/services/dictionary.service";

export default {
  productNeedAccount: {
    name: "warehouse_product_need_account",
    entities: [
      {
        name: "ID",
        type: "string",
        readonly: true
      },
      {
        name: "Warehouse",
        type: "dropdown",
        readonly: true,
        allowEmpty: false,
        multiple: false,
        required: true,
        optionsService: dictionaryServices.fetchWarehouses
      },
      {
        name: "Finished Product",
        type: "dropdown",
        readonly: true,
        required: true,
        optionsService: dictionaryServices.fetchFinishedProducts
      },
      {
        name: "Bottle Size",
        type: "dropdown",
        readonly: true,
        required: true,
        optionsService: dictionaryServices.fetchBottleSizes
      },
      {
        name: "Bottles",
        type: "number",
        readonly: true,
        required: true
      },
      {
        name: "Bottles WH",
        type: "number",
        readonly: true
      },
      {
        name: "Bottles Diff",
        type: "number",
        readonly: true
      },
      {
        name: "Cases",
        type: "number",
        readonly: true
      },
      {
        name: "Cases WH",
        type: "number",
        readonly: true
      },
      {
        name: "Cases Diff",
        type: "number",
        readonly: true
      }
    ],
    services: {
      fetchData: warehouseServices.fetchProductNeedAccountReport
    },
    actions: {}
  }
};

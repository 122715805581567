import warehouseServices from "@/services/warehouse.service";
import dictionaryServices from "@/services/dictionary.service";

export default {
  invoiceItems: {
    name: "warehouse_invoice_items",
    entities: [
      {
        name: "ID",
        type: "string",
        readonly: true
      },
      {
        name: "Finished Product",
        type: "dropdown",
        readonly: true,
        required: true,
        optionsService: dictionaryServices.fetchFinishedProducts
      },
      {
        name: "Organic Status",
        type: "dropdown",
        readonly: true,
        required: true,
        optionsService: dictionaryServices.fetchOrganicStatuses
      },
      {
        name: "Batch Number",
        type: "string",
        readonly: true,
        required: true
      },
      {
        name: "Bottle Size",
        type: "dropdown",
        readonly: true,
        required: true,
        optionsService: dictionaryServices.fetchBottleSizes
      },
      {
        name: "Bottles",
        type: "number",
        readonly: false,
        required: true
      },
      {
        name: "Cases",
        type: "number",
        readonly: true
      },
      {
        name: "PG",
        type: "number",
        readonly: true
      },
      {
        name: "WG",
        type: "number",
        readonly: true
      },
      {
        name: "Cost per Case",
        type: "number",
        readonly: false,
        required: true
      },
      {
        name: "Line Cost",
        type: "number",
        readonly: true
      }
    ],
    services: {
      fetchData: warehouseServices.fetchInvoiceItems
    },
    //actions: ["Create", "Delete"]
    actions: {
      Create: {
        validator: () => true
      },
      Delete: {
        validator: () => true
      }
    }
  }
};

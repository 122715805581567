import salesDetailsReportServices from "@/services/Warehouse/sales-details-report.service";

export default {
  salesSummary: {
    name: "warehouse_sales_details_sales_summary",
    entities: [
      {
        name: "Warehouse",
        type: "string"
      },
      {
        name: "Summary $",
        type: "number"
      },

      {
        name: "Number of Bottles",
        type: "number"
      },

      {
        name: "Proof Gallons",
        type: "number"
      },
      {
        name: "Wine Gallons",
        type: "number"
      }
    ],
    services: {
      fetchData: salesDetailsReportServices.fetchSalesSummaryReport
    }
  }
};

import trackTimeServices from "@/services/track-time.service";

export default {
  importantDates: {
    name: "important_dates",
    entities: [
      {
        name: "ID",
        type: "string",
        readonly: true
      },
      {
        name: "Date",
        type: "date",
        readonly: false,
        defaultRange: "This year full",
        required: true
      },
      {
        name: "Category",
        type: "dropdown",
        readonly: false,
        optionsService: trackTimeServices.fetchImportantDatesCategories,
        required: true
      },
      {
        name: "Description",
        type: "string",
        readonly: false,
        allowEmpty: false,
        required: true
      }
    ],
    services: {
      fetchRecord: () => {},
      fetchData: trackTimeServices.fetchImportantDates,
      saveRecord: trackTimeServices.saveImportantDates,
      deleteRecord: trackTimeServices.deleteImportantDate
    },
    actions: {
      Create: {
        validator: () => true
      },
      Delete: {
        validator: () => true
      }
    }
  }
};

import { api } from '@/shared/api'

const mileageRate = {
  fetchMileageRates: async payload => {
    let res = await api.post(`reimbursements/mileage-rate`, payload)

    return res
  },
  fetchMileageRateOnDate: async payload => {
    let res = await api.post(`reimbursements/mileage-rate/on-date`, payload)

    return res
  },
  saveMileageRate: async payload => {
    let url = payload.ID
      ? `reimbursements/mileage-rate/${payload.ID}`
      : `reimbursements/mileage-rate`

    return api.put(url, payload)
  },
  deleteMileageRate: async ID => {
    return api.delete(`reimbursements/mileage-rate/${ID}`)
  }
}

export default mileageRate

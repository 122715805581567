import warehouseServices from '@/services/warehouse.service'
import dictionaryServices from '@/services/dictionary.service'

export default {
  availableProductsTotals: {
    name: 'warehouse_inventory',
    entities: [
      {
        name: 'Finished Product',
        type: 'dropdown',
        optionsService: dictionaryServices.fetchFinishedProducts
      },

      {
        name: 'Warehouse',
        type: 'dropdown',
        optionsService: dictionaryServices.fetchWarehouses
      },
      {
        name: 'Warehouse Status',
        type: 'dropdown',
        optionsService: dictionaryServices.fetchWarehouseStatuses
      },
      {
        name: 'Organic Status',
        type: 'dropdown',
        optionsService: dictionaryServices.fetchOrganicStatuses
      },
      {
        name: 'Bottle Size',
        type: 'dropdown',
        optionsService: dictionaryServices.fetchBottleSizes
      },
      {
        name: 'Date Bottled',
        type: 'date'
      },
      {
        name: 'Shipped Before',
        type: 'date',
        range: 'Empty'
      },
      {
        name: 'Shipped After',
        type: 'date',
        range: 'Empty'
      }
    ],
    services: {
      fetchData: warehouseServices.fetchAvailableProductsTotals,
      deleteRecord: () => {}
    },
    detailsRouterName: null,
    actions: {}
  }
}

import dictionaryServices from "@/services/dictionary.service";

export default {
  bottlingsSearch: {
    name: "warehouse_bottlings_search",
    entities: [
      {
        name: "ID",
        type: "string",
        readonly: true
      },
      {
        name: "Bottling Number",
        type: "string",
        readonly: true
      },

      {
        name: "Batch Number",
        type: "string",
        readonly: true
      },
      {
        name: "Finished Product",
        type: "string",
        readonly: true
      },
      {
        name: "Organic Status",
        type: "dropdown",
        readonly: true,
        required: true,
        optionsService: dictionaryServices.fetchOrganicStatuses
      },

      {
        name: "Bottle Size",
        type: "dropdown",
        readonly: true,
        required: true,
        optionsService: dictionaryServices.fetchBottleSizes
      },

      {
        name: "Bottles Remaining",
        type: "number",
        readonly: true,
        required: true
      },
      {
        name: "Bottles Selected",
        type: "number",
        readonly: false,
        required: true
      },

      {
        name: "Cost per Case",
        type: "number",
        readonly: true,
        required: true
      }
    ],
    services: {
      fetchData: null
    },
    actions: {}
  }
};

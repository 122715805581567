import userManagementService from '@/services/user-management.service'

export default {
  bonusPayments: {
    name: 'user_management_bonus_payments',
    entities: [
      {
        name: 'ID',
        type: 'string',
        readonly: true
      },
      {
        name: 'User Name',
        type: 'dropdown',
        readonly: false,
        optionsService: userManagementService.fetchActiveUsers,
        required: true
      },
      {
        name: 'YTD Amount',
        type: 'number',
        readonly: true,
        allowEmpty: true,
        required: false
      },

      {
        name: 'Bonus Date',
        type: 'date',
        readonly: false
      },
      {
        name: 'Amount',
        type: 'number',
        readonly: false,
        allowEmpty: false,
        required: true
      },
      {
        name: 'Description',
        type: 'string',
        readonly: false,
        allowEmpty: false,
        required: true
      },
      { name: 'Created By', type: 'string', readonly: true },
      {
        name: 'Created',
        type: 'date',
        readonly: true
      },
      {
        name: 'Updated',
        type: 'date',
        readonly: true
      }
    ],
    services: {
      fetchRecord: () => {},
      fetchData: userManagementService.fetchBonusPayments,
      saveRecord: userManagementService.saveBonusPayment,
      deleteRecord: userManagementService.deleteBonusPayment
    },
    actions: {
      Create: {
        validator: () => true
      },
      Delete: {
        validator: () => true
      }
    }
  }
}

import trackTimeServices from "@/services/track-time.service";
import dictionaryServices from "@/services/dictionary.service";
import userManagementServices from "@/services/user-management.service";

export default {
  shiftTemplate: {
    name: "shift_template",
    entities: [
      {
        name: "ID",
        type: "int",
        readonly: true
      },
      {
        name: "Start Date",
        type: "date",
        readonly: false,
        defaultRange: "This year full",
        required: true
      },
      {
        name: "Week Day",
        type: "dropdown",
        /*TODO
        controlType: "dropdown"
        dataType:"weekday"
        */
        readonly: false,
        optionsService: dictionaryServices.fetchWeekDays,
        required: true
      },
      {
        name: "Start Time",
        type: "time",
        readonly: false,
        optionsService: async () => [],
        required: true
      },
      {
        name: "End Time",
        type: "time",
        readonly: false,
        optionsService: async () => [],
        required: true
      },
      {
        name: "Responsibles",
        type: "dropdown",
        readonly: false,
        optionsService: userManagementServices.fetchActiveUsers,
        required: true,
        multiple: true
      },
      {
        name: "Group",
        type: "dropdown",
        readonly: false,
        optionsService: userManagementServices.fetchUserGroups,
        required: true,
        multiple: false
      }
    ],
    services: {
      fetchData: trackTimeServices.fetchShiftTemplates,
      fetchRecord: trackTimeServices.fetchShiftTemplate,
      saveRecord: trackTimeServices.saveShiftTemplate,
      deleteRecord: trackTimeServices.deleteShiftTemplate
    },
    tabularRouterName: "Shift templates",
    detailsRouterName: "Shift template submission",

    actions: {
      Create: {
        validator: () => true
      },
      View: {
        validator: () => true
      },
      Edit: {
        validator: () => true
      },

      Delete: {
        validator: () => true
      }
    }
  }
};

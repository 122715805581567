import distributorServices from "@/services/Distributor/distributor.service";

export default {
  distributorMarkets: {
    name: "distributor_markets",
    entities: [
      {
        name: "ID",
        type: "string",
        readonly: true
      },
      {
        name: "Distributor",
        type: "string",
        readonly: true
      },
      {
        name: "Market",
        type: "string",
        readonly: true
      },
      {
        name: "Country",
        type: "string",
        readonly: true
      },
      {
        name: "State",
        type: "string",
        readonly: true
      }
    ],
    services: {
      fetchRecord: () => {},
      fetchData: distributorServices.fetchDistributorMarkets,
      saveRecord: () => {},
      viewRecord: () => {
        console.log("view market");
      },
      deleteRecord: () => {}
    },
    actions: {
      View: {
        validator: () => true
      }
    }
  }
};

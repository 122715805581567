import { api } from "@/shared/api";

const distributor = {
  fetchDistributorMarkets: async payload => {
    let res = await api.get(`distributors/${payload.account_id}/markets`);

    return res;
  }
};

export default distributor;

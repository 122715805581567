import salesDetailsReportServices from "@/services/Warehouse/sales-details-report.service";

export default {
  otherSamples: {
    name: "warehouse_sales_details_other_samples",
    entities: [
      {
        name: "Distributor",
        type: "string"
      },
      {
        name: "Product Name",
        type: "string"
      },
      {
        name: "Bottle Size",
        type: "string"
      },
      {
        name: "Number of Bottles",
        type: "number"
      },
      {
        name: "Number of Cases",
        type: "number"
      },
      {
        name: "Alcohol %",
        type: "number"
      },
      {
        name: "Proof Gallons",
        type: "number"
      },
      {
        name: "Wine Gallons",
        type: "number"
      }
    ],
    services: {
      fetchData: salesDetailsReportServices.fetchOtherSamplesReport
    }
  }
};

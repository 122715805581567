import salesDetailsReportServices from "@/services/Warehouse/sales-details-report.service";

export default {
  salesByState: {
    name: "warehouse_sales_details_by_state",
    entities: [
      {
        name: "Country",
        type: "string"
      },

      {
        name: "State",
        type: "string"
      },

      {
        name: "Number of Bottles",
        type: "number"
      },

      {
        name: "Number of Cases",
        type: "number"
      },

      {
        name: "Costs",
        type: "number"
      },

      {
        name: "Discount",
        type: "number"
      },
      {
        name: "Proof Gallons",
        type: "number"
      },

      {
        name: "Wine Gallons",
        type: "number"
      },

      {
        name: "Distributor",
        type: "string"
      },

      {
        name: "% Sales",
        type: "number"
      }
    ],
    services: {
      fetchData: salesDetailsReportServices.fetchSalesByStateReport
    }
  }
};

import salesByProduct from "./sales-by-product.model.js";
import salesByInvoice from "./sales-by-invoice.model.js";
import salesByState from "./sales-by-state.model.js";
import storeTransfers from "./store-transfers.model.js";
import storeSamples from "./store-samples.model.js";
import otherSamples from "./other-samples.model.js";
import tastingSamples from "./tasting-samples.model.js";
import salesSummary from "./sales-summary.model.js";

import dictionaryServices from "@/services/dictionary.service";

export default {
  salesDetailsReport: {
    name: "warehouse_sales_details",
    entities: [
      {
        name: "Territory",
        type: "dropdown",
        readonly: false,
        allowEmpty: false,
        multiple: true,
        required: true,
        optionsService: dictionaryServices.fetchTerritories
      },
      {
        name: "Market",
        type: "dropdown",
        readonly: false,
        allowEmpty: false,
        multiple: true,
        required: true,
        optionsService: dictionaryServices.fetchMarkets
      },

      {
        name: "Warehouse",
        type: "dropdown",
        readonly: false,
        allowEmpty: false,
        required: true,
        optionsService: dictionaryServices.fetchWarehouses
      },
      {
        name: "Distributor",
        type: "dropdown",
        required: true,
        optionsService: dictionaryServices.fetchDistributors
      },
      {
        name: "Organic Status",
        type: "dropdown",
        readonly: true,
        required: true,
        optionsService: dictionaryServices.fetchOrganicStatuses
      },
      {
        name: "Invoice Date",
        type: "date"
      }
    ],
    ...salesByProduct,
    ...salesByInvoice,
    ...salesByState,
    ...storeTransfers,
    ...storeSamples,
    ...tastingSamples,
    ...otherSamples,
    ...salesSummary
  }
};

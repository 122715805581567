import warehouseServices from "@/services/warehouse.service";
import dictionaryServices from "@/services/dictionary.service";

export default {
  incomingOrders: {
    name: "warehouse_inc_orders",
    entities: [
      {
        name: "ID",
        type: "string",
        readonly: true
      },
      {
        name: "Warehouse",
        type: "dropdown",
        readonly: false,
        allowEmpty: false,
        multiple: false,
        required: true,
        optionsService: dictionaryServices.fetchWarehouses,
        defaultValue: { id: 2, label: "4241 N Ravenswood Ave 60613" }
      },
      {
        name: "PO Date",
        type: "date"
      },
      {
        name: "Est Finish Date",
        type: "date",
        required: true
      },
      {
        name: "PO Number",
        type: "string",
        readonly: false,
        required: true
      },
      {
        name: "Distributor",
        type: "dropdown",
        required: true,
        optionsService: dictionaryServices.fetchDistributors
      },
      {
        name: "Currency",
        type: "dropdown",
        required: true,
        optionsService: dictionaryServices.fetchCurrencies,
        defaultValue: { id: 1, label: "USD" }
      },
      {
        name: "Discount",
        type: "number",
        readonly: false
      },
      {
        name: "Total",
        type: "number",
        readonly: true
      },
      {
        name: "Status",
        type: "string",
        readonly: true,


      },
      {
        name: "Pick List ID",
        type: "string",
        readonly: true
      },
      {
        name: "Notes",
        type: "string",
        isTextArea: true
      },
      {
        name: "Created By",
        type: "string",
        readonly: true
      },
      {
        name: "Modified By",
        type: "string",
        readonly: true
      },
      {
        name: "Created",
        type: "string",
        readonly: true
      },
      {
        name: "Modified",
        type: "string",
        readonly: true
      },
      {
        name: "Invoice Number",
        type: "string",
        readonly: true
      }
    ],
    services: {
      fetchData: warehouseServices.fetchIncomingOrders,
      fetchRecord: warehouseServices.fetchIncomingOrder,
      saveRecord: warehouseServices.saveIncomingOrder,
      deleteRecord: warehouseServices.deleteIncomingOrder
    },
    tabularRouterName: "Incoming orders",
    detailsRouterName: "Incoming order submission",
    //actions: ["Create", "View", "Edit", "Delete"],
    actions: {
      Create: {
        validator: () => { }
      },
      View: {
        validator: () => true
      },
      Edit: {
        validator: row => !(row["Invoice Number"] > 0)
      },
      Delete: {
        validator: row => !(row["Invoice Number"] > 0)
      }
    }
  }
};

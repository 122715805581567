import { api } from "@/shared/api";

export default {
  async fetchSalesByProductReport(payload) {
    return api.post("warehouse/sales-details/sales-by-product", payload);
  },

  async fetchSalesByInvoiceReport(payload) {
    return api.post("warehouse/sales-details/sales-by-invoice", payload);
  },

  async fetchSalesByStateReport(payload) {
    return api.post("warehouse/sales-details/sales-by-state", payload);
  },

  async fetchStoreTransfersReport(payload) {
    return api.post("warehouse/sales-details/store-transfers", payload);
  },
  async fetchStoreSamplesReport(payload) {
    return api.post("warehouse/sales-details/store-samples", payload);
  },
  async fetchTastingSamplesReport(payload) {
    return api.post("warehouse/sales-details/tasting-samples", payload);
  },
  async fetchOtherSamplesReport(payload) {
    return api.post("warehouse/sales-details/other-samples", payload);
  },
  async fetchSalesSummaryReport(payload) {
    return api.post("warehouse/sales-details/sales-summary", payload);
  }

};

import salesDetailsReportServices from "@/services/Warehouse/sales-details-report.service";

export default {
  salesByInvoice: {
    name: "warehouse_sales_details_by_invoice",
    entities: [
      {
        name: "Invoice Number",
        type: "string"
      },
      {
        name: "QB Number",
        type: "string"
      },
      {
        name: "Invoice Date",
        type: "date"
      },
      {
        name: "Accountname",
        type: "string"
      },
      {
        name: "Number of Bottles",
        type: "number"
      },
      {
        name: "Number of Cases",
        type: "number"
      },
      {
        name: "Line Cost",
        type: "number"
      },
      {
        name: "Discount",
        type: "number"
      },
      {
        name: "Exchange",
        type: "number"
      },
      {
        name: "Currency",
        type: "number"
      },
      {
        name: "Proof Gallons",
        type: "number"
      },
      {
        name: "Wine Gallons",
        type: "number"
      }
    ],
    services: {
      fetchData: salesDetailsReportServices.fetchSalesByInvoiceReport
    }
  }
};

import { api } from "@/shared/api";

const importantDates = {
  fetchImportantDates: async payload => {
    let res = await api.post(`timetracker/important-dates`, payload);

    return res;
  },
  saveImportantDates: async payload => {
    console.log("saveImportantDates", payload);

    let url = payload.ID
      ? `timetracker/important-dates/${payload.ID}`
      : `timetracker/important-dates`;

    return api.put(url, payload);
  },
  deleteImportantDate: async ID => {
    return api.delete(`timetracker/important-dates/${ID}`);
  },

  fetchImportantDatesCategories: async () => {
    return [
      { id: 0, name: "None" },
      { id: 1, name: "Company holiday" },
      { id: 2, name: "Internal event" },
      { id: 3, name: "Public event" }
    ];
  }
};

export default importantDates;

export default {
  invoiceProductsTotals: {
    name: "warehouse_invoice_products_totals",
    entities: [
      {
        name: "Finished Product",
        type: "string",
        readonly: true
      },
      {
        name: "Bottle Size",
        type: "string",
        readonly: true
      },
      {
        name: "Total",
        type: "string",
        readonly: true
      }
    ],
    services: {
      fetchData: () => []
    },
    actions: {}
  }
};
